import React from "react";
import { Link } from "react-router-dom";
import LSPPDF from "../pdf/lending-service-providers-for-afpl.pdf";
import FAIR from "../pdf/Fair-Practice-Code_Print20Version.pdf";
import INTERREST from "../pdf/InterestRatePolicy.pdf";
// import LOAN from "../pdf/Loan20.pdf";
import SMA from "../pdf/SMA_NPA_Classification_Norms_V_1.0_Website_Copy.pdf";
import TAC from "../pdf/terms-and-conditions.pdf";
import PP from "../pdf/privacy-policy.pdf";
import GRC from "../pdf/GrievanceRedressalPolicy.pdf";
import OP from "../pdf/integrated-ombudsman-scheme2021.pdf";
import LPA from "../pdf/lending-partners-for-AFPL.pdf";
import DDLG from "../pdf/jnbc-website-aum-march-25.pdf";
import ARNT from "../pdf/AnnualReturns2019-2020.pdf";
import ARTO from "../pdf/AnnualReturns2020-2021.pdf";
import ARTT from "../pdf/AnnualReturns2021-2022.pdf";
import ARTS from "../pdf/AnnualReturns2022-2023.pdf";

export default function Regulatory() {
  const image = {
    bottom: "10px",
    position: "relative",
  };

  const singleServicesH3a = {
    fontSize: "19px",
  };
  const singleServices = {
    height: "200px !important",
    padding: "30px !important",
  };

  const singleServicesH3 = {
    position: "absolute",
    bottom: "10px",
  };

  return (
    <>
      <div className="hero-1 oh pos-rel pt-150 " id="regulatory">
        <div className="container">
          <h2>Regulatory</h2>
        </div>

        <div className="container mt-50" id="regu">
          <div className="row">
            {/* <!-- col --> */}
            <div className="col-lg-4 col-md-6">
              <div className="single-services" style={singleServices}>
                <div className="image mt-3" style={image}>
                  <Link target="_blank" to={TAC}>
                    <img src="images/pdf.png" alt="image" width="50px" />
                  </Link>
                </div>
                <h3 style={singleServicesH3}>
                  <Link style={singleServicesH3a} target="_blank" to={TAC}>
                    Terms and Conditions
                  </Link>
                </h3>
              </div>
            </div>
            {/* <!-- /col --> */}
            {/* <!-- col --> */}
            <div className="col-lg-4 col-md-6">
              <div className="single-services" style={singleServices}>
                <div className="image mt-3" style={image}>
                  <Link target="_blank" to={PP}>
                    <img src="images/pdf.png" alt="image" width="50px" />
                  </Link>
                </div>
                <h3 style={singleServicesH3}>
                  <Link style={singleServicesH3a} target="_blank" to={PP}>
                    Privacy Policy
                  </Link>
                </h3>
              </div>
            </div>
            {/* <!-- /col --> */}

            {/* <!-- col --> */}
            <div className="col-lg-4 col-md-6">
              <div className="single-services" style={singleServices}>
                <div className="image mt-3" style={image}>
                  <Link target="_blank" to={OP}>
                    <img src="images/pdf.png" alt="image" width="50px" />
                  </Link>
                </div>
                <h3 style={singleServicesH3}>
                  <Link style={singleServicesH3a} target="_blank" to={OP}>
                    Ombudsman Policy
                  </Link>
                </h3>
              </div>
            </div>
            {/* <!-- /col --> */}

            {/* <!-- col --> */}
            <div className="col-lg-4 col-md-6">
              <div className="single-services" style={singleServices}>
                <div className="image mt-3" style={image}>
                  <Link target="_blank" to={GRC}>
                    <img src="images/pdf.png" alt="image" width="50px" />
                  </Link>
                </div>
                <h3 style={singleServicesH3}>
                  <Link style={singleServicesH3a} target="_blank" to={GRC}>
                    Grievance Redressal Policy
                  </Link>
                </h3>
              </div>
            </div>
            {/* <!-- /col --> */}

            {/* <!-- col --> */}
            <div className="col-lg-4 col-md-6">
              <div className="single-services" style={singleServices}>
                <div className="image mt-3" style={image}>
                  <Link target="_blank" to={FAIR}>
                    <img src="images/pdf.png" width="50px" />
                  </Link>
                </div>
                <h3 style={singleServicesH3}>
                  <Link style={singleServicesH3a} target="_blank" to={FAIR}>
                    Fair Practices Code
                  </Link>
                </h3>
              </div>
            </div>
            {/* <!-- /col --> */}

            {/* <!-- col --> */}
            {/* <div className="col-lg-4 col-md-6">
              <div className="single-services" style={singleServices}>
                <div className="image mt-3" style={image}>
                  <Link target="_blank" to={LOAN}>
                    <img src="images/pdf.png" alt="image" width="50px" />
                  </Link>
                </div>
                <h3 style={singleServicesH3}>
                  <Link style={singleServicesH3a} target="_blank" to={LOAN}>
                    Loan Terms
                  </Link>
                </h3>
              </div>
            </div> */}
            {/* <!-- /col --> */}

            {/* <!-- col --> */}
            <div className="col-lg-4 col-md-6">
              <div className="single-services" style={singleServices}>
                <div className="image mt-3" style={image}>
                  <Link target="_blank" to={INTERREST}>
                    <img src="images/pdf.png" alt="image" width="50px" />
                  </Link>
                </div>
                <h3 style={singleServicesH3}>
                  <Link
                    style={singleServicesH3a}
                    target="_blank"
                    to={INTERREST}
                  >
                    Interest Rate Policy
                  </Link>
                </h3>
              </div>
            </div>
            {/* <!-- /col --> */}

            {/* <!-- col --> */}
            <div className="col-lg-4 col-md-6">
              <div className="single-services" style={singleServices}>
                <div className="image mt-3" style={image}>
                  <Link target="_blank" to={SMA}>
                    <img src="images/pdf.png" alt="image" width="50px" />
                  </Link>
                </div>
                <h3 style={singleServicesH3}>
                  <Link style={singleServicesH3a} target="_blank" to={SMA}>
                    Consumer Education Literature
                  </Link>
                </h3>
              </div>
            </div>
            {/* <!-- /col --> */}

            {/* <!-- col --> */}
            <div className="col-lg-4 col-md-6">
              <div className="single-services" style={singleServices}>
                <div className="image mt-3" style={image}>
                  <Link target="_blank" to={LSPPDF}>
                    <img src="images/pdf.png" alt="image" width="50px" />
                  </Link>
                </div>
                <h3 style={singleServicesH3}>
                  <Link style={singleServicesH3a} target="_blank" to={LSPPDF}>
                    LSPs for AFPL
                  </Link>
                </h3>
              </div>
            </div>
            {/* <!-- /col --> */}

            {/* <!-- col --> */}
            <div className="col-lg-4 col-md-6">
              <div className="single-services" style={singleServices}>
                <div className="image mt-3" style={image}>
                  <Link target="_blank" to={LSPPDF}>
                    <img src="images/pdf.png" alt="image" width="50px" />
                  </Link>
                </div>
                <h3 style={singleServicesH3}>
                  <Link
                    style={singleServicesH3a}
                    target="_blank"
                    to="https://sachet.rbi.org.in/"
                  >
                    Sachet Portal
                  </Link>
                </h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-services" style={singleServices}>
                <div className="image mt-3" style={image}>
                  <Link target="_blank" to={LPA}>
                    <img src="images/pdf.png" alt="image" width="50px" />
                  </Link>
                </div>
                <h3 style={singleServicesH3}>
                  <Link style={singleServicesH3a} target="_blank" to={LPA}>
                    Lending Partners for AFPL
                  </Link>
                </h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-services" style={singleServices}>
                <div className="image mt-3" style={image}>
                  <Link target="_blank" to={DDLG}>
                    <img src="images/pdf.png" alt="image" width="50px" />
                  </Link>
                </div>
                <h3 style={singleServicesH3}>
                  <Link style={singleServicesH3a} target="_blank" to={DDLG}>
                    Disclosures on Default Loss Guarantee in Digital Lending
                  </Link>
                </h3>
              </div>
            </div>
            {/* <!-- /col --> */}
            <div className="col-lg-4 col-md-6">
              <div className="single-services" style={singleServices}>
                <div className="image mt-3" style={image}>
                  <Link target="_blank" to={ARNT}>
                    <img src="images/pdf.png" alt="image" width="50px" />
                  </Link>
                </div>
                <h3 style={singleServicesH3}>
                  <Link style={singleServicesH3a} target="_blank" to={ARNT}>
                    Annual Returns 2019-2020
                  </Link>
                </h3>
              </div>
            </div>
            {/* <!-- /col --> */}
            <div className="col-lg-4 col-md-6">
              <div className="single-services" style={singleServices}>
                <div className="image mt-3" style={image}>
                  <Link target="_blank" to={ARTO}>
                    <img src="images/pdf.png" alt="image" width="50px" />
                  </Link>
                </div>
                <h3 style={singleServicesH3}>
                  <Link style={singleServicesH3a} target="_blank" to={ARTO}>
                    Annual Returns 2020-2021
                  </Link>
                </h3>
              </div>
            </div>
            {/* <!-- /col --> */}
            <div className="col-lg-4 col-md-6">
              <div className="single-services" style={singleServices}>
                <div className="image mt-3" style={image}>
                  <Link target="_blank" to={ARTT}>
                    <img src="images/pdf.png" alt="image" width="50px" />
                  </Link>
                </div>
                <h3 style={singleServicesH3}>
                  <Link style={singleServicesH3a} target="_blank" to={ARTT}>
                    Annual Returns 2021-2022
                  </Link>
                </h3>
              </div>
            </div>
            {/* <!-- /col --> */}
            <div className="col-lg-4 col-md-6">
              <div className="single-services" style={singleServices}>
                <div className="image mt-3" style={image}>
                  <Link target="_blank" to={ARTS}>
                    <img src="images/pdf.png" alt="image" width="50px" />
                  </Link>
                </div>
                <h3 style={singleServicesH3}>
                  <Link style={singleServicesH3a} target="_blank" to={ARTS}>
                    Annual Returns 2022-2023
                  </Link>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
